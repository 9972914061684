<template>
  <div class="w-100 h-100">
    <div
      ref="map"
      class="w-100 h-100"
    />
    <div
      class="left-menu-container col-12 col-lg-4"
      :class="{'h-100': (filter.searchText != '' || activeRequest) && !selectedRequest}"
    >
      <Transition name="fade">
        <div
          v-if="(filter.searchText != '' || activeRequest) && !selectedRequest"
          class="card w-100 h-100"
          style="border-radius: 0px 0px 0px 0px;"
        />
      </Transition>
      <div
        v-if="!selectedRequest"
        class="mini-menu w-100 h-100"
      >
        <div
          class="card d-flex flex-row align-items-center justify-content-between"
          style="margin-bottom: 8px; background-color: #148827"
        >
          <div
            class="d-flex align-items-center"
            style="padding: 8px"
          >
            <b-img
              :src="abtLogo1"
              height="48px"
            />
            <h4
              class="text-white mb-0"
              style="margin-left: 8px"
            >
              องค์การบริหารส่วนตำบลมาบไผ่
            </h4>
          </div>
          <div>
            <feather-icon
              class="text-white cursor-pointer mr-1"
              icon="MenuIcon"
              size="2x"
              @click="activeRequest = !activeRequest"
            />
          </div>
        </div>

        <div
          class="card"
          style="margin-bottom: 8px;"
        >
          <div class="w-100 d-flex align-items-center">
            <feather-icon
              class="ml-1"
              icon="SearchIcon"
            />
            <input
              id="search"
              v-model="filter.searchText"
              class="form-control"
              placeholder="ค้นหาคำร้อง"
            >
          </div>
          <hr class="mt-0">
          <div class="d-flex justify-content-between m-1">
            <b-media
              class="cursor-pointer"
              no-body
              @click="filter.status = 0"
            >
              <b-media-aside class="d-none d-sm-block">
                <b-avatar
                  size="48"
                  variant="warning"
                >
                  <feather-icon
                    size="24"
                    icon="ClipboardIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-warning">
                  {{ countStatus(items, 0) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  รอดำเนินการ
                </b-card-text>
              </b-media-body>
            </b-media>
            <b-media
              class="cursor-pointer"
              no-body
              @click="filter.status = 1"
            >
              <b-media-aside class="d-none d-sm-block">
                <b-avatar
                  size="48"
                  variant="info"
                >
                  <feather-icon
                    size="24"
                    icon="ClockIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-info">
                  {{ countStatus(items, 1) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  กำลังดำเนินการ
                </b-card-text>
              </b-media-body>
            </b-media>
            <b-media
              class="cursor-pointer"
              no-body
              @click="filter.status = 2"
            >
              <b-media-aside class="d-none d-sm-block">
                <b-avatar
                  size="48"
                  variant="success"
                >
                  <feather-icon
                    size="24"
                    icon="SmileIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-success">
                  {{ countStatus(items, 2) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  เสร็จสิ้น
                </b-card-text>
              </b-media-body>
            </b-media>
          </div>
        </div>

        <div
          class="card cursor-pointer"
          style="margin-bottom: 0px;"
          @click="filter.status = undefined; activeRequest = true;"
        >
          <div class="d-flex justify-content-between p-1">
            <div class="d-flex flex-column justify-content-between">
              <div>คำร้องทั้งหมด</div>
              <div><h4 class="font-weight-bolder mb-0 text-primary">
                {{ items.length }}
              </h4></div>
            </div>
            <div><b-avatar
              size="48"
              variant="primary"
            >
              <feather-icon
                size="24"
                icon="MailIcon"
              />
            </b-avatar></div>
          </div>
        </div>

        <div
          class="d-flex flex-wrap"
          style="margin-bottom: 4px;"
        >
          <div
            v-for="t in type"
            :key="t._id"
            class="card cursor-pointer mb-0"
            style="padding: 4px 8px 4px 8px; margin-right: 8px; margin-top: 4px;"
            :style="{backgroundColor: t.color}"
            @click="filter.type = t"
          >
            <h6 class="m-0 text-white">
              {{ t.name }}
            </h6>
          </div>
        </div>

        <div
          class="w-100"
          style="overflow-y: scroll"
        >
          <div
            class="d-flex justify-content-between align-items-center"
          >
            <h4
              class="text-truncate mb-0"
              style="padding-top: 10px"
            >
              {{ getFilterLabel() }}
            </h4>
            <feather-icon
              v-if="getFilterLabel() !== 'ทั้งหมด'"
              class="cursor-pointer"
              icon="XIcon"
              size="24"
              @click="resetFilter"
            />
          </div>
          <hr>
          <Transition name="fade">
            <div v-if="filter.searchText != '' || activeRequest">
              <div
                v-for="item in listSearched"
                :key="item._id"
                class="request-item w-100 pt-1 cursor-pointer"
                @click="selectRequest($event, item)"
              >
                <div class="d-flex">
                  <b-img
                    v-if="item.img"
                    style="border-radius: 8px; object-fit: cover;"
                    :src="item.img | publicImage"
                    width="100px"
                    height="100px"
                  />
                  <b-img
                    v-else
                    style="border-radius: 8px; object-fit: cover;"
                    :src="mock"
                    width="100px"
                    height="100px"
                  />
                  <div
                    style="margin-left: 8px"
                  >
                    <h4 class="text-bold mb-0">
                      {{ item.title }}
                    </h4>
                    <!-- <div>
                      โดย: {{ item.user.profileName }}
                    </div> -->
                    <div class="text-detail">
                      {{ item.detail }}
                    </div>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <Transition name="fade">
        <div
          v-if="selectedRequest && !showFullDetail"
          :class="{'h-100': showFullDetail}"
          style="padding: 8px"
        >
          <div
            class="card"
          >
            <div
              class="w-100 cursor-pointer"
              style="padding: 8px"
            >
              <div
                class="d-flex"
              >
                <b-img
                  v-if="selectedRequest.img"
                  style="border-radius: 8px; object-fit: cover;"
                  :src="selectedRequest.img | publicImage"
                  width="100px"
                  height="100px"
                />
                <b-img
                  v-else
                  style="border-radius: 8px; object-fit: cover;"
                  :src="mock"
                  width="100px"
                  height="100px"
                />
                <div
                  class="w-100"
                  style="margin-left: 8px;overflow: auto;"
                >
                  <h4 class="text-bold mb-0">
                    {{ selectedRequest.title }}
                    <feather-icon
                      class="float-right"
                      size="1x"
                      icon="XIcon"
                      @click="selectedRequest = undefined"
                    />
                  </h4>
                  <!-- <div>
                    โดย: {{ selectedRequest.user.profileName }}
                  </div> -->
                  <div class="text-detail">
                    {{ selectedRequest.detail }}
                  </div>
                </div>
              </div>
              <div
                class="text-info text-center cursor-pointer"
                style="padding-top: 8px"
                @click="showFullDetail = true"
              >
                ดูเพิ่มเติม
              </div>
            </div>
          </div>
        </div>
      </Transition>

      <Transition name="full">
        <div
          v-if="selectedRequest && showFullDetail"
          style="padding: 8px; height: 100vh"
        >
          <div
            class="card h-100"
          >
            <div
              class="w-100 h-100 cursor-pointer"
            >
              <div class="h-100 d-flex flex-column">
                <div
                  class="position-absolute"
                  style="padding: 8px"
                >
                  <b-button
                    style="background-color: white !important"
                    class="btn-icon rounded-circle"
                    @click="showFullDetail = false"
                  >
                    <feather-icon
                      class="text-secondary"
                      size="1x"
                      icon="ChevronLeftIcon"
                    />
                  </b-button>
                </div>
                <LightGallery
                  :images="[{title: 'รูปภาพ', url: selectedRequest.imgUrl}]"
                  :index="index"
                  :disable-scroll="true"
                  @close="index = null"
                />
                <b-img
                  v-if="selectedRequest.img"
                  class="w-100"
                  style="border-radius: 8px 8px 0px 0px; object-fit: cover;"
                  :src="selectedRequest.img | publicImage"
                  height="200px"
                  @click="index = 0"
                />
                <b-img
                  v-else
                  class="w-100"
                  style="border-radius: 8px 8px 0px 0px; object-fit: cover;"
                  :src="mock"
                  height="200px"
                  @click="index = 0"
                />
                <div class="ml-1 mr-1">
                  <h4 class="text-bold mt-1 mb-0">
                    {{ selectedRequest.title }}
                  </h4>
                  <!-- <div>
                    โดย: {{ selectedRequest.user.profileName }}
                  </div> -->
                  <div class="text-secondary">
                    {{ selectedRequest.detail }}
                  </div>
                </div>
                <h4 class="ml-1 mr-1 mt-1">
                  <feather-icon
                    class="mr-1"
                    size="24"
                    icon="ListIcon"
                  />
                  ติดตามการดำเนินการ
                </h4>
                <div
                  class="ml-1 mr-1 mt-1 h-100 pb-1 pt-1"
                  style="overflow-y: scroll"
                >
                  <app-timeline>
                    <app-timeline-item
                      v-for="timeline in selectedRequest.timeline"
                      :key="timeline._id"
                      :variant="timeline.status | timelineColor"
                    >
                      <div
                        class="d-flex flex-sm-row flex-column flex-wrap align-items-center justify-content-between mb-1"
                      >
                        <div v-if="timeline.user.role === 'Admin'">
                          <div
                            class="d-flex justify-content-start align-items-center"
                          >
                            <!-- avatar -->
                            <b-avatar
                              :src="timeline.user.img"
                              size="30"
                              class="mr-1"
                            />
                            <!--/ avatar -->
                            <div class="profile-user-info">
                              <h6 class="mb-0">
                                {{ timeline.user.profileName }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="timeline.user._id == timeline.user._id">
                          <div
                            class="d-flex justify-content-start align-items-center"
                          >
                            <!-- avatar -->
                            <b-avatar
                              size="30"
                              class="mr-1"
                            />
                            <!--/ avatar -->
                            <div class="profile-user-info">
                              <h6 class="mb-0">
                                ผู้แจ้งคำร้อง
                              </h6>
                            </div>
                          </div>
                        </div>
                        <small class="text-muted">{{
                          timeline.createdAt | dateTime
                        }}</small>
                      </div>
                      <b-img
                        v-if="timeline.img"
                        :src="timeline.img | publicImage"
                        style="max-width: 100%"
                      />
                      <div style="margin-top: -5px; margin-bottom: 5px">
                        <small class="text-muted">{{
                          timeline.message
                        }}</small>
                      </div>
                    </app-timeline-item>
                  </app-timeline>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>

    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import liff from '@line/liff'
import { Loader } from '@googlemaps/js-api-loader'
import abtLogo1 from '@/assets/images/logo/abt-logo1.png'
import axios from 'axios'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { LightGallery } from 'vue-light-gallery'
import mock from '@/assets/images/mock/empty.jpeg'

export default {
  components: {
    BAvatar,
    BImg,
    BMedia,
    BMediaBody,
    BMediaAside,
    BCardText,
    BButton,
    AppTimeline,
    AppTimelineItem,
    LightGallery,
  },
  data() {
    return {
      activeRequest: false,
      filter: {
        searchText: '',
        type: undefined,
        status: undefined,
      },
      showFullDetail: false,
      selectedRequest: undefined,
      profile: undefined,
      map: undefined,
      google: undefined,
      items: [],
      type: [],
      images: [],
      index: null,
      abtLogo1,
      mock,
    }
  },
  computed: {
    listSearched() {
      let arr = this.items
      if (this.filter.searchText !== '') {
        arr = arr.filter(r => {
          console.log(r)
          let found = false
          if (r.title.toLowerCase().indexOf(this.filter.searchText.toLowerCase()) !== -1) found = true
          if (r.detail.toLowerCase().indexOf(this.filter.searchText.toLowerCase()) !== -1) found = true
          if (r.user.profileName.toLowerCase().indexOf(this.filter.searchText.toLowerCase()) !== -1) found = true
          return found
        })
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.filter.type) arr = arr.filter(r => r.requestType._id === this.filter.type._id)
      if (this.filter.status) arr = arr.filter(r => r.status === this.filter.status)
      return arr
    },
  },
  watch: {
    filter: {
      handler() {
        this.activeRequest = true
      },
      deep: true,
    },
    selectedRequest: {
      async handler(v) {
        if (v) {
          const latLng = new this.google.maps.LatLng(Number.parseFloat(v.lat), Number.parseFloat(v.lng))
          await this.map.panTo(latLng)
          this.map.setZoom(16)
        } else {
          this.map.setZoom(14)
        }
      },
    },
  },
  async created() {
    const loader = new Loader({
      apiKey: 'AIzaSyA15zZ_DKfS-SJLZ-b4hFFcx-1q4QXFC5E',
      version: 'weekly',
      libraries: ['places'],
      region: 'TH',
      language: 'th',
    })
    this.google = await loader.load()
    const mapOptions = {
      center: {
        lat: 13.348721686329366,
        lng: 101.0833993312976,
      },
      zoom: 14,
    }
    this.map = new this.google.maps.Map(this.$refs.map, mapOptions)

    const res = await axios.get('/request')
    this.items = await Promise.all(res.data.items.map(async (i, index) => {
      const marker = new this.google.maps.Marker({
        position: {
          lat: Number.parseFloat(i.lat),
          lng: Number.parseFloat(i.lng),
        },
        map: this.map,
      })
      marker.addListener('click', () => {
        this.selectedRequest = i
      })
      return { ...i, ...{ marker, index, imgUrl: i.img ? `${process.env.VUE_APP_SERVER_URL}/image/${i.img}` : undefined } }
    }))

    this.images = this.items.map(r => ({ title: 'รูปภาพ', url: r.img ? `${process.env.VUE_APP_SERVER_URL}/image/${r.img}` : undefined }))

    const resType = await axios.get('/request/type')
    this.type = resType.data.items

    try {
      if (this.$route.query.request) {
        const id = this.$route.query.request
        // eslint-disable-next-line no-underscore-dangle
        this.selectedRequest = this.items.find(i => i._id === id)
      }
    } catch (e) {
      console.log('ไม่พบตำร้อง')
    }

    await liff.init({
      liffId: '1657233329-92n7voR4', // Use own liffId
    })
    if (liff.isLoggedIn()) {
      const profile = await liff.getProfile()
      this.profile = profile
    }
  },
  methods: {
    resetFilter() {
      this.filter = {
        searchText: '',
        type: undefined,
        status: undefined,
      }
    },
    getFilterLabel() {
      const arr = []
      if (this.filter.status) arr.push(this.getNameStatus(this.filter.status))
      if (this.filter.type) arr.push(this.filter.type.name)
      const str = arr.join(' / ')
      if (str === '') return 'ทั้งหมด'
      return str
    },
    getNameStatus(status) {
      if (status === 0) {
        return 'รอดำเนินการ'
      } if (status === 1) {
        return 'กำลังดำเนินการ'
      } if (status === 2) {
        return 'เสร็จสิ้น'
      }
      return 'ทั้งหมด'
    },
    countStatus(items, status) {
      return items.reduce((acc, cur) => {
        if (cur.status === status) {
          return acc + 1
        }
        return acc
      }, 0)
    },
    selectRequest(event, item) {
      this.selectedRequest = item
    },
    async getCurrentLocation() {
      try {
        if (navigator.geolocation) {
          const location = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(loc => {
              resolve({
                lat: loc.coords.latitude,
                lng: loc.coords.longitude,
              })
            }, err => {
              reject(err)
            })
          })
          return location
        }
        return {
          lat: 13.348721686329366,
          lng: 101.0833993312976,
        }
      } catch (error) {
        console.log(error)
        return {
          lat: 13.348721686329366,
          lng: 101.0833993312976,
        }
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/core.scss';

  .left-menu-container {
    top: 0;
    left: 0;
    position: absolute;
    padding: 0px;
  }

  .mini-menu {
    top: 0;
    left: 0;
    position: absolute;
    padding: 8px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  #search {
    border: none;
    height: 40px;
    width: 100%;
  }

  #search:focus{
    outline: none;
    box-shadow: none;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .bounce-enter-active {
    // animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
    // animation: bounce-in 0.5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .full-enter-active {
    animation: full-in 0.25s;
    transform-origin: top;
  }
  .full-leave-active {
    animation: full-in 0.25s reverse;
    transform-origin: top;
  }
  @keyframes full-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .text-detail {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .request-item:hover {
    background: rgb(241, 241, 241);
  }
</style>
